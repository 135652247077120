<template>
  <div class="myLink">
    <van-nav-bar title="米因AI健康自測" left-arrow @click-left="$router.back()" class="van-nav-bar-title" />
    <div class="myLink-content">
      <img :src="myLinkBG" alt="myLink">
      <div class="myLink-footer" v-if="$store.state.isLogin">
        <div class="footer-btn" @click="toPage('/myLink/question/four')">AI智慧健康體檢</div>
        <div class="footer-btn-history" @click="toPage('/myLink/history')">查看歷史評估記錄</div>
      </div>
    </div>

  </div>
</template>

<script>
import myLinkBG from '@/assets/images/myLink/myLink.jpg'
import { loginMyLink } from '@/js/axios'
import { Base64 } from 'js-base64';
import { Message } from 'element-ui';

export default {
  name: 'myLink',

  data () {
    return {
      myLinkBG,
    };
  },
  created () {
    const thirdPartyToken = this.$route.query.thirdPartyToken
    const lastToken = localStorage.getItem('thirdPartyToken')
    if (thirdPartyToken === lastToken) {

    } else if (thirdPartyToken) {
      const data = {
        thirdPartyToken
      }
      loginMyLink(data).then(res => {
        if (res.code === 200) {
          localStorage.setItem('thirdPartyToken', thirdPartyToken)
          const resData = JSON.parse(Base64.decode(res.data))
          this.$store.commit('checkLogin', true)
          Message.success('登录成功')
          this.$store.dispatch('login', resData.access_token)
        } else {
          localStorage.removeItem('thirdPartyToken')
          this.$store.commit('checkLogin', false)
          Message.error('登录失败,thirdPartyToken非法')
        }
      })
    } else if (!lastToken) {
      localStorage.clear()
      Message.error('不存在合法的thirdPartyToken')
    }
  },
  mounted () {

  },

  methods: {
    toPage (url) {
      this.$router.push(url);
    },
  },
};
</script>


<style lang="scss" scoped>
.myLink {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #6279E4;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  >div {
    width: 100%;
  }

  .van-nav-bar-title {
    flex: 0 0 auto;
  }

  .myLink-content {
    flex: 1;
    overflow-y: auto;

    img {
      width: 100%;
    }

    .myLink-footer {
      padding: 32px 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .footer-btn {
        width: 100%;
        font-weight: 500;
        font-size: 0.44rem;
        color: #033359;
        line-height: 0.6rem;
        letter-spacing: 1px;
        text-align: center;
        font-style: normal;
        background: linear-gradient(180deg, #95ECF6 0%, #75C3FF 100%);
        border-radius: 0.4rem;
        border: 0.04rem solid;
        border-image: linear-gradient(360deg, rgba(236, 247, 255, 1), rgba(168, 218, 255, 1)) 0.04 0.04;
      }


      .footer-btn-history {
        width: fit-content;
        padding: 0 .48rem;
        margin-top: .48rem;
        font-size: 0.28rem;
        color: #F9F5FF;
        line-height: 0.4rem;
        text-align: left;
        font-style: normal;
        background: #798EEE;
        border-radius: 0.08rem;
        border: 0.02rem solid #E2E7FF;
        text-align: center;
      }
    }
  }


}
</style>
